import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import Header from '../../../Include/Header';
import Sidebar from '../../../Include/Sidebar';
import NdtOfferHeader from '../../../../../Components/NDT/NdtOfferHeader';
import { PdfDownloadErp } from '../../../../../Components/ErpPdf/PdfDownloadErp';
import Loader from '../../../Include/Loader';
import { getUserNdtMaster } from '../../../../../Store/Store/Ndt/NdtMaster';
import { getMultiNdtOffer } from '../../../../../Store/MutipleDrawing/MultiNDT/TestNdtOffer/MultiTestOfferList';
import MultiTestOfferList from '../../Components/MultiTestOffer/MultiTestOfferList';

const MultiUtOffer = () => {

    const dispatch = useDispatch();
    const [totalItems, setTotalItems] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [search, setSearch] = useState("");
    const [limit, setlimit] = useState(10);
    const [disable, setDisable] = useState(true);

    useEffect(() => {
        dispatch(getUserNdtMaster({ status: '' })).then((response) => {
            const ndtData = response.payload?.data;
            const findNdt = ndtData?.find((nt) => nt?.name === 'UT');
            if (findNdt && disable) {
                dispatch(getMultiNdtOffer({ status: '', type: findNdt._id }));
                setDisable(false);
            }
        }).catch((error) => console.error("Error fetching NDT Master data:", error));
    }, [disable]);

    const entity = useSelector((state) => state.getUserNdtOffer?.user?.data);

    const commentsData = useMemo(() => {
        let computedComments = entity;
        if (search) {
            computedComments = computedComments.filter(
                (ut) =>
                    ut.name?.toLowerCase()?.includes(search?.toLowerCase())
            );
        }
        setTotalItems(computedComments?.length);
        return computedComments?.slice(
            (currentPage - 1) * limit,
            (currentPage - 1) * limit + limit
        );
    }, [currentPage, search, limit, entity]);

    const handleRefresh = () => {
        setSearch('');
        setDisable(true);
    }

    const handleDownloadOffer = (elem) => {
        const bodyFormData = new URLSearchParams();
        bodyFormData.append('ndt_offer_no', elem.ndt_offer_no);
        bodyFormData.append('print_date', true);
        PdfDownloadErp({ apiMethod: 'post', url: 'one-ndt-offer-download', body: bodyFormData });
    }

    const [isSidebarOpen, setIsSidebarOpen] = useState(false);
    const handleOpen = () => {
        setIsSidebarOpen(!isSidebarOpen);
    }

    return (
        <>
            <div className={`main-wrapper ${isSidebarOpen ? 'slide-nav' : ''}`}>
                <Header handleOpen={handleOpen} />
                <Sidebar />

                <div className="page-wrapper">
                    <div className="content">
                        <NdtOfferHeader name={'Ultrasonic Test Offer List'} />


                        {disable === false ? (
                            <MultiTestOfferList
                                name={'Ultrasonic Test Offer List'}
                                url={'/user/project-store/manage-ut-offer'}
                                commentsData={commentsData}
                                limit={limit}
                                setlimit={setlimit}
                                currentPage={currentPage}
                                setCurrentPage={setCurrentPage}
                                totalItems={totalItems}
                                setSearch={setSearch}
                                handleRefresh={handleRefresh}
                                handleDownloadOffer={handleDownloadOffer}
                            />
                        ) : <Loader />}

                    </div>
                </div>
            </div>
        </>
    )
}

export default MultiUtOffer