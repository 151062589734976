import React, { useEffect, useMemo, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import Header from '../../../Include/Header';
import Sidebar from '../../../Include/Sidebar';
import PageHeader from '../../Components/Breadcrumbs/PageHeader';
import DropDown from '../../../../../Components/DropDown';
import { Pagination, Search } from '../../../Table';
import SubmitButton from '../../Components/SubmitButton/SubmitButton';

const ManageMultiUtOffer = () => {

  const location = useLocation();
  const navigate = useNavigate();
  const [tableData, setTableData] = useState([]);
  const data = location.state;
  const [totalItems, setTotalItems] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState("");
  const [limit, setlimit] = useState(10);
  const [disable, setDisable] = useState(false);
  const [editRowIndex, setEditRowIndex] = useState(null);
  const [editFormData, setEditFormData] = useState({
    thickness: '',
    remarks: '',
  });

  useEffect(() => {
    if (data?._id) {
      setTableData(location.state?.items);
    }
  }, [data?._id]);

  const handleEditClick = (index, row) => {
    setEditRowIndex(index);
    setEditFormData({
      thickness: row.thickness,
      remarks: row.remarks,
    });
  }

  const handleEditFormChange = (e) => {
    const { name, value } = e.target;
    setEditFormData({
      ...editFormData,
      [name]: value,
    });
  }

  const handleSaveClick = () => {
    const updatedData = [...tableData];
    const dataIndex = (currentPage - 1) * limit + editRowIndex;
    updatedData[dataIndex] = { ...updatedData[dataIndex], ...editFormData };
    setTableData(updatedData);
    setEditRowIndex(null);
  }

  const handleCancelClick = () => {
    setEditRowIndex(null);
  };

  const handleSubmit = () => {

  }

  const commentsData = useMemo(() => {

  }, [search, currentPage, limit]);

  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const handleOpen = () => {
    setIsSidebarOpen(!isSidebarOpen);
  }

  return (
    <>
      <div className={`main-wrapper ${isSidebarOpen ? "slide-nav" : ""}`}>
        <Header handleOpen={handleOpen} />
        <Sidebar />

        <div className="page-wrapper">
          <div className="content">

            <PageHeader breadcrumbs={[
              { name: "Dashboard", link: "/user/project-store/dashboard", active: false },
              { name: "Ultrasonic Test Offer List", link: "/user/project-store/ut-offer-management", active: false },
              { name: `${data?._id ? 'Edit' : 'Add'} Ultrasonic Test Offer`, active: true }
            ]} />

            <div className='row'>
              <div className="col-sm-12">
                <div className="card">
                  <div className="card-body">
                    <form>
                      <div className="col-12">
                        <div className="form-heading">
                          <h4>Manage Ultrasonic Offer Details</h4>
                        </div>
                      </div>

                      <div className='row'>
                        <div className="col-12 col-md-4 col-xl-4">
                          <div className="input-block local-forms">
                            <label>Client </label>
                            <input className='form-control' readOnly />
                          </div>
                        </div>
                        <div className='col-12 col-md-4 col-xl-4'>
                          <div className="input-block local-forms">
                            <label>Work Order / PO No.</label>
                            <input className='form-control' readOnly />
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>

            <div className='row'>
              <div className='col-12'>
                <div className="card card-table show-entire">
                  <div className="card-body">
                    <div className="page-table-header mb-2">
                      <div className="row align-items-center">
                        <div className="col">
                          <div className="doctor-table-blk">
                            <h3>Section Details List</h3>
                            <div className="doctor-search-blk">
                              <div className="top-nav-search table-search-blk">
                                <form>
                                  <Search onSearch={(value) => {
                                    setSearch(value);
                                    setCurrentPage(1);
                                  }} />
                                  {/* eslint-disable jsx-a11y/anchor-is-valid */}
                                  <a className="btn"><img src="/assets/img/icons/search-normal.svg"
                                    alt="search" /></a>
                                </form>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="pageDropDown col-auto text-end float-end ms-auto download-grp">
                          <DropDown limit={limit} onLimitChange={(val) => setlimit(val)} />
                        </div>
                      </div>
                    </div>

                    <div className="table-responsive" style={{ minHeight: 0 }}>
                      <table className="table border-0 custom-table comman-table  mb-0">
                        <thead>
                          <tr>
                            <th>Sr.</th>
                            <th>Section Details</th>
                            <th>Quantity</th>
                            <th>Item No.</th>
                            <th>Grid No.</th>
                            <th>Joint Type</th>
                            <th>Welding Process</th>
                            <th>Weldor No.</th>
                            <th>Thickness(mm)</th>
                            <th>Remarks</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {commentsData?.map((elem, i) =>
                            <tr key={i}>
                              <td>{(currentPage - 1) * limit + i + 1}</td>
                            </tr>
                          )}
                          {commentsData?.length === 0 ? (
                            <tr>
                              <td colSpan="999">
                                <div className="no-table-data">
                                  No Data Found!
                                </div>
                              </td>
                            </tr>
                          ) : null}
                        </tbody>
                      </table>
                    </div>
                    <div className="row align-center mt-3 mb-2">
                      <div className="col-sm-12 col-md-6 col-lg-6 col-xxl-6">
                        <div className="dataTables_info" id="DataTables_Table_0_info" role="status"
                          aria-live="polite">Showing {Math.min(limit, totalItems)} from {totalItems} data</div>
                      </div>
                      <div className="col-sm-12 col-md-6 col-lg-6 col-xxl-6 ">
                        <div className="dataTables_paginate paging_simple_numbers"
                          id="DataTables_Table_0_paginate">
                          <Pagination
                            total={totalItems}
                            itemsPerPage={limit}
                            currentPage={currentPage}
                            onPageChange={(page) => setCurrentPage(page)}
                          />
                        </div>
                      </div>
                    </div>



                  </div>
                </div>
              </div>
            </div>

            <SubmitButton
              disable={disable}
              handleSubmit={handleSubmit}
              link={'/user/project-store/ut-offer-management'}
              finalReq={data?.items}
              buttonName={'Generate UT Offer'}
            />

          </div>
        </div>

      </div>
    </>
  )
}

export default ManageMultiUtOffer