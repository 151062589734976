import React from 'react'
import { Modal } from 'react-bootstrap'

const MultiFdModal = ({ showItem, drawId, handleCloseModal, title }) => {
    return (
        <>
            <Modal size='lg' show={showItem} backdrop="static" onHide={handleCloseModal}>
                <Modal.Header closeButton>
                    <Modal.Title>{title}</Modal.Title>
                </Modal.Header>
                <Modal.Body>

                </Modal.Body>
            </Modal>
        </>
    )
}

export default MultiFdModal