import React from 'react'
import { useNavigate } from 'react-router-dom'

const SubmitButton = ({ finalReq = [], disable, handleSubmit, link = "", buttonName, isFd, handleStatusChange, data, showFd = false }) => {

    const navigate = useNavigate();

    return (
        <div className='row'>
            <div className="col-sm-12">
                <div className="card">
                    <div className="card-body">
                        {showFd && (
                            <div className="col-12 col-md-4 col-xl-4">
                                <div className="input-block select-gender">
                                    <label className="gen-label">Select Procedure <span className="login-danger">*</span></label>
                                    <div className="form-check-inline">
                                        <label className="form-check-label">
                                            <input type="radio" name="isFd"
                                                value="accept"
                                                className="form-check-input" checked={isFd === true}
                                                onChange={handleStatusChange} disabled={data?._id} />Final Dimension
                                        </label>
                                    </div>
                                    <div className="form-check-inline">
                                        <label className="form-check-label">
                                            <input type="radio" name="isFd" value="reject"
                                                checked={isFd === false}
                                                onChange={handleStatusChange}
                                                className="form-check-input" disabled={data?._id} /> Fitup
                                        </label>
                                    </div>
                                </div>
                            </div>
                        )}
                        <div className="col-12 text-end">
                            <div className="doctor-submit text-end">
                                {(finalReq?.length === 0 || finalReq === undefined) ? (
                                    <button type="button"
                                        className="btn btn-primary submit-form me-2" onClick={handleSubmit} disabled={disable}>
                                        {disable ? 'Processing...' : buttonName}</button>
                                ) : (
                                    <button type="button" className="btn btn-primary submit-form me-2"
                                        onClick={() => navigate(link)}>Back</button>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SubmitButton