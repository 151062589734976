import { configureStore } from '@reduxjs/toolkit'

// Admin
import adminLoginSlice from '../Store/Admin/Login/Login';
import adminForgetPasswordSlice from '../Store/Admin/Login/ForgetPassword';
import adminOtpSlice from '../Store/Admin/Login/Otp';
import resetAdminPasswordSlice from '../Store/Admin/Login/ResetPassword';

import getBankSlice from '../Store/Admin/Payroll/Bank/Bank';
import getAdminBankSlice from '../Store/Admin/Payroll/Bank/AdminBank';
import addBankSlice from '../Store/Admin/Payroll/Bank/ManageBank';

import getShiftSlice from '../Store/Admin/Payroll/Shift/Shift';
import getAdminShiftSlice from '../Store/Admin/Payroll/Shift/AdminShift'
import addShiftSlice from '../Store/Admin/Payroll/Shift/ManageShift';

import getGroupSlice from '../Store/Admin/Payroll/Group/getGroup';
import getAdminGroupSlice from '../Store/Admin/Payroll/Group/AdminGroup';
import addGroupSlice from '../Store/Admin/Payroll/Group/AdminGroup';

import getDesignationSlice from '../Store/Admin/Payroll/Designation/Designation';
import getAdminDesignationSlice from '../Store/Admin/Payroll/Designation/AdminDesgination';
import addDesignationSlice from '../Store/Admin/Payroll/Designation/ManageDesignation';

import getAuthPersonSlice from '../Store/Admin/Payroll/AuthPerson/AuthPerson';
import getAdminAuthSlice from '../Store/Admin/Payroll/AuthPerson/AdminAuthPerson';
import addAuthPersonSlice from '../Store/Admin/Payroll/AuthPerson/ManageAuthPerson';

import getSkillSlice from '../Store/Admin/Payroll/Skill/Skill';
import getAdminSkillSlice from '../Store/Admin/Payroll/Skill/AdminSkill';
import addSkillSlice from '../Store/Admin/Payroll/Skill/AddSkill';

import getEmployeeTypeSlice from '../Store/Admin/Payroll/EmployeeType/EmployeeType';
import getAdminEmployeeTypeSlice from '../Store/Admin/Payroll/EmployeeType/AdminEmployeeType';
import addEmployeeTypeSlice from '../Store/Admin/Payroll/EmployeeType/ManageEmployeeType';

import getDepartmentSlice from '../Store/Admin/Payroll/Department/Department';
import getAdminDepartmentSlice from '../Store/Admin/Payroll/Department/AdminDepartment';
import addDepartmentSlice from '../Store/Admin/Payroll/Department/ManageDepartment';

import getYearSlice from '../Store/Admin/Payroll/Year/Year';
import getAdminYearSlice from '../Store/Admin/Payroll/Year/AdminYear';
import addYearSlice from '../Store/Admin/Payroll/Year/ManageYear';

// admin Store
import getErpRoleSlice from '../Store/Admin/ErpRole/ErpRole';
import adminGetPartySlice from '../Store/Admin/Party/GetParty';
import adminGetEmployeeSlice from '../Store/Admin/Employee/Employee';
import getFirmSlice from '../Store/Admin/Firm/Firm';

import AdminContractorMasterSlice from './Admin/Contractor/AdminContractorMaster';

import adminGetPartyTagSlice from '../Store/Admin/PartyTag/AdminPartyTag';
import adminGetPartyGroupSlice from '../Store/Admin/PartyTag/AdminPartyGroup';

import getAdminItemDataSlice from '../Store/Admin/Item/getAdminItem';


// Store ========================================================================

import userForgetPasswordSlice from '../Store/Store/Login/UserForget';
import userOtpSlice from '../Store/Store/Login/UserOtp';
import resetUserPasswordSlice from '../Store/Store/Login/resetPassword';

import getUnitSlice from '../Store/Store/StoreMaster/Unit/Unit';
import getAdminUnitSlice from '../Store/Store/StoreMaster/Unit/AdminUnit';
import addUnitSlice from '../Store/Store/StoreMaster/Unit/ManageUnit';

import getCategorySlice from '../Store/Store/StoreMaster/Category/Category';
import getAdminCategorySlice from '../Store/Store/StoreMaster/Category/AdminCategory';
import addCategorySlice from '../Store/Store/StoreMaster/Category/ManageCategory';

import getTransportSlice from '../Store/Store/StoreMaster/Transport/Transport';
import getAdminTransportSlice from '../Store/Store/StoreMaster/Transport/AdminTransport';
import addTransportSlice from '../Store/Store/StoreMaster/Transport/ManageTransport';

import getLocationSlice from '../Store/Store/StoreMaster/InventoryLocation/Location';
import getAdminLocationSlice from '../Store/Store/StoreMaster/InventoryLocation/AdminLocation';
import addLocationSlice from '../Store/Store/StoreMaster/InventoryLocation/ManageLocation';
import getPartySlice from '../Store/Store/Party/Party';
import getAdminPartySlice from '../Store/Store/Party/AdminParty';
import addPartySlice from '../Store/Store/Party/ManageParty';
import getPartyGroupSlice from '../Store/Store/StoreMaster/PartyGroup/PartyGroup';
import getAdminPartyGroupSlice from '../Store/Store/StoreMaster/PartyGroup/PartyAdminGroup';
import addPartyGroupSlice from '../Store/Store/StoreMaster/PartyGroup/ManageParty';
import getUserDepartmentSlice from '../Store/Store/StoreMaster/Department/Department';
import getUserEmployeeSlice from '../Store/Store/StoreMaster/Employee/Employee';
import getPartyTagSlice from '../Store/Store/StoreMaster/PartyTag/PartyTag';
import getStoreAuthPersonSlice from '../Store/Store/StoreMaster/AuthPerson/AuthPerson';
import getAdminStoreAuthSlice from '../Store/Store/StoreMaster/AuthPerson/AdminAuthPerson';
import addStoreAuthPersonSlice from '../Store/Store/StoreMaster/AuthPerson/ManageAuthPerson';
import getItemSlice from '../Store/Store/Item/Item';
import getAdminItemSlice from '../Store/Store/Item/AdminItem';
import addItemSlice from '../Store/Store/Item/ManageItem';
import getProjectSlice from '../Store/Store/Project/Project';
import getStoreEmployeeSlice from '../Store/Store/Employee/Employee';
import getAdjustmentSlice from '../Store/Store/Adjustment/getAdjustment';
// import getItemStockSlice from '../Store/Store/Stock/getStock';
import getStoreDashboardSlice from '../Store/Store/Dashboard/Dashboard'
import getTransactionItemSlice from '../Store/Store/TransactionItem/TransactionItem';
import getOrderSlice from '../Store/Store/Order/Order';
import getOrderReturnSlice from '../Store/Store/Order/Return';

import getGenTagSlice from '../Store/Store/GenralMaster/TagGenMaster';
import getItemSummarySlice from '../Store/Store/Report/ItemSummary';
import getItemLedgerSlice from '../Store/Store/Report/ItemLedger';
import getReorderItemsSlice from '../Store/Store/Report/ReOrderItems';

//  ERp ================================================================================
// Planner -----------------------------------------------------

import getDrawingSlice from '../Store/Erp/Planner/Draw/Draw';
import getUserProfileSlice from '../Store/Store/Profile/Profile';
import getUserIssueSlice from '../Store/Store/Issue/Issue';
import getRequestSlice from '../Store/Store/Request/getRequest';
import getUserDrawTrasactionSlice from '../Store/Store/TransactionItem/getDrawTransaction';
import getUserAdminDrawSlice from '../Store/Erp/Planner/Draw/UserAdminDraw';
import getUserIssueRequestSlice from '../Store/Store/Issue/IssueRequest';
import getUserOfferSlice from '../Store/Store/Offer/getUserOffer';
import addIssueSlice from '../Store/Store/Issue/AddIssue';
import getIssueSlice from '../Store/Store/Issue/GetIssue';
import getOneIssueSlice from '../Store/Store/Issue/GetOneIssue';

import getReleseNoteSlice from '../Store/Erp/ReleseNote/ReleseNote';

// Material Coordinator APIS ==========================================================================

import getUserIssueAcceptanceSlice from '../Store/Store/Issue/IssueAcceptance';

// Execution =============================
import getUserFitupSlice from '../Store/Store/Execution/getUserFitup';
import getUserWeldVisualSlice from '../Store/Store/Execution/getUserWeldVisual';

import getUserJointTypeSlice from '../Store/Store/JointType/JointType';
import getUserNdtMasterSlice from '../Store/Store/Ndt/NdtMaster';
import getUserContractorSlice from '../Store/Store/ContractorMaster/ContractorMaster';

import getUserPaintSystemSlice from '../Store/Store/PaintSystem/PaintSystem';
import getUserWpsMasterSlice from '../Store/Store/WpsMaster/WpsMaster';
import getUserWelderMasterSlice from '../Store/Store/WelderMaster/WelderMaster';
import getUserProcedureMasterSlice from '../Store/Store/Procedure/ProcedureMaster';
import getUserPaintManufactureSlice from '../Store/Store/PaintManufacture/PaintManufacture';

import getStockReportSlice from '../Store/Store/Stock/getStockReport';
import getStockReportListSlice from '../Store/Store/Stock/getStockReportList';

import getUserMainNdtMasterSlice from '../Store/Store/Ndt/MainNdtMaster';
import getUserNdtOfferSlice from '../Store/Store/Ndt/NdtOffer';

import getUserUtClearanceSlice from '../Store/Store/Ndt/UT/UtClearance';
import getUserRtClearanceSlice from '../Store/Store/Ndt/RT/RtClearance';
import getUserMptClearanceSlice from '../Store/Store/Ndt/MPT/MptClearance';

import getUserLptClearanceSlice from '../Store/Store/Ndt/LPT/LptClearance';

import getUserFinalDimensionSlice from '../Store/Store/Execution/getUserFinalDimension';
import getUserInspectionSummarySlice from '../Store/Store/InspectionSummary/GetInspectionSummary';
import getDispatchNotesSlice from '../Store/Store/DispatchNote/GetDispatchNote';

import getUserSurfaceSlice from '../Store/Erp/Painting/Surface/Surface';
import getGenMasterSlice from './Store/GenralMaster/GenMaster';
import AddOrderSlice from './Store/Order/ManageOrder';
import AddOrderReturnSlice from './Store/Order/ManageOrderReturn';
import getSingleOrderSlice from './Store/Order/GetSingleOrder';
import getSingleOrderReturnSlice from './Store/Order/GetSingleOrderReturn';
import getMainStoreStockSlice from '../Store/Store/MainStore/MainStock';
import getMainStockSlice from '../Store/Store/Stock/getMainStock';
import getUserMioSlice from '../Store/Erp/Painting/Mio/GetMio';
import getUserFinalCoatingSlice from '../Store/Erp/Painting/FinalCoating/FinalCoating';
import getPackSlice from '../Store/Erp/Packing/Packing';

import getInvoiceSlice from '../Store/Erp/Invoice/Invoice';
import getDprSlice from '../Store/Erp/Dpr/Dpr';
import getPmsStockSlice from './Store/PMSStock/PMS';

import getUserProjectLocationSlice from '../Store/Erp/ProjectLocation/ProjectLocation';

// Multiple Drawings =================================================================
import getMultipleIssueRequestSlice from '../Store/MutipleDrawing/IssueRequest/MultipleIssueRequest';
import getMultipleIssueAccSlice from '../Store/MutipleDrawing/IssueAcc/MultipleIssueAcc';
import getMultipleDrawItemsSlice from '../Store/MutipleDrawing/MultipleDrawing/MultipleDrawItems';
import updateMultiGridSlice from '../Store/MutipleDrawing/MultipleDrawing/UpdateGridBal';

import updateIssueAccGridSlice from '../Store/MutipleDrawing/IssueAcc/UpdateIssueAccGrid';
import getMultiFitupSlice from '../Store/MutipleDrawing/MultiFitup/getMultiFitup';

import getMultiWeldVisualSlice from '../Store/MutipleDrawing/MultiWeldVisual/getMultiWeldVisual';
import updateFitupGridSlice from '../Store/MutipleDrawing/MultiWeldVisual/UpdateFitupGrid';

import manageFitupOffTableSlice from '../Store/MutipleDrawing/MultiFitup/manageFitupOffTable';
import getFitupOfferTableSlice from '../Store/MutipleDrawing/MultiFitup/getFitupOfferTable';

import removeFitupOffTableSlice from '../Store/MutipleDrawing/MultiFitup/removeFitupOffertable';
import updateFitupOffTableSlice from '../Store/MutipleDrawing/MultiFitup/updateFitupOfferTable';

import getWeldOfferTableSlice from '../Store/MutipleDrawing/MultiWeldVisual/getWeldOfferTable';
import manageWeldOfferTableSlice from '../Store/MutipleDrawing/MultiWeldVisual/manageWeldTableOffer';
import updateWeldOffTableSlice from '../Store/MutipleDrawing/MultiWeldVisual/updateWeldOfferTable';
import removeWeldOffTableSlice from '../Store/MutipleDrawing/MultiWeldVisual/removeWeldOfferTable';

import updateNDTGridSlice from '../Store/MutipleDrawing/MultiNDT/UpdateNDTGrid';
import manageNDTOfferTableSlice from '../Store/MutipleDrawing/MultiNDT/manageNDTTableOffer';
import getNDTOfferTableSlice from '../Store/MutipleDrawing/MultiNDT/getNDTOffertable';
import removeNDTOffTableSlice from '../Store/MutipleDrawing/MultiNDT/removeNdtOfferTable';

import updateNDTOffTableSlice from '../Store/MutipleDrawing/MultiNDT/updateNDTOfferTable';
import getUserMultiNdtMasterSlice from '../Store/MutipleDrawing/MultiNDT/getUserMultiNdtMaster';

import getMultiNdtOfferSlice from '../Store/MutipleDrawing/MultiNDT/TestNdtOffer/MultiTestOfferList';

import getMultipleGridSlice from '../Store/MutipleDrawing/MultipleDrawing/MultipleGrid';

// =============================================================================================================================

export default configureStore({
    reducer: {
        login: adminLoginSlice,
        forgetPassword: adminForgetPasswordSlice,
        adminOtp: adminOtpSlice,
        resetAdminPassword: resetAdminPasswordSlice,
        adminGetParty: adminGetPartySlice,
        adminGetEmployee: adminGetEmployeeSlice,
        getFirm: getFirmSlice,

        // Admin APIS=========================================================================
        //====== Payroll

        getBank: getBankSlice,
        getAdminBank: getAdminBankSlice,
        addBank: addBankSlice,

        getShift: getShiftSlice,
        getAdminShift: getAdminShiftSlice,
        addShift: addShiftSlice,

        getGroup: getGroupSlice,
        getAdminGroup: getAdminGroupSlice,
        addGroup: addGroupSlice,

        getDesignation: getDesignationSlice,
        getAdminDesignation: getAdminDesignationSlice,
        addDesignation: addDesignationSlice,

        getAuthPerson: getAuthPersonSlice,
        getAdminAuth: getAdminAuthSlice,
        addAuthPerson: addAuthPersonSlice,

        getAdminSkill: getAdminSkillSlice,
        getSkill: getSkillSlice,
        addSkill: addSkillSlice,

        getEmployeeType: getEmployeeTypeSlice,
        getAdminEmployeeType: getAdminEmployeeTypeSlice,
        addEmployeeType: addEmployeeTypeSlice,

        getDepartment: getDepartmentSlice,
        getAdminDepartment: getAdminDepartmentSlice,
        addDepartment: addDepartmentSlice,

        getYear: getYearSlice,
        getAdminYear: getAdminYearSlice,
        addYearSlice: addYearSlice,

        adminGetPartyTag: adminGetPartyTagSlice,
        adminGetPartyGroup: adminGetPartyGroupSlice,

        getErpRole: getErpRoleSlice,

        // Admin Store
        getAdminItemData: getAdminItemDataSlice,
        getAdminContractor: AdminContractorMasterSlice,
        // Store =================================================

        userForgetPasswordSlice: userForgetPasswordSlice,
        userOtp: userOtpSlice,
        resetUserPassword: resetUserPasswordSlice,

        getUnit: getUnitSlice,
        getAdminUnit: getAdminUnitSlice,
        addUnit: addUnitSlice,

        getCategory: getCategorySlice,
        getAdminCategory: getAdminCategorySlice,
        addCategory: addCategorySlice,

        getTransport: getTransportSlice,
        getAdminTransport: getAdminTransportSlice,
        addTransport: addTransportSlice,

        getLocation: getLocationSlice,
        getAdminLocation: getAdminLocationSlice,
        addLocation: addLocationSlice,

        getParty: getPartySlice,
        getAdminParty: getAdminPartySlice,
        addParty: addPartySlice,

        getPartyGroup: getPartyGroupSlice,
        getAdminPartyGroup: getAdminPartyGroupSlice,
        addPartyGroup: addPartyGroupSlice,

        getUserDepartment: getUserDepartmentSlice,
        getUserEmployee: getUserEmployeeSlice,

        getPartyTag: getPartyTagSlice,

        getStoreAuthPerson: getStoreAuthPersonSlice,
        getAdminStoreAuth: getAdminStoreAuthSlice,
        addStoreAuthPerson: addStoreAuthPersonSlice,

        getItem: getItemSlice,
        getAdminItem: getAdminItemSlice,
        addItem: addItemSlice,

        //get gen Master
        getGenMaster: getGenMasterSlice,
        getGenTag: getGenTagSlice,

        //purchase order slice 
        addPurchaseorder: AddOrderSlice,
        getPurchaseorder: getOrderSlice,
        getSinglePurchaseorder: getSingleOrderSlice,

        //issue purchase
        addIssue: addIssueSlice,
        getIssue: getIssueSlice,
        getOneIssue: getOneIssueSlice,


        addPurchaseorderReturn: AddOrderReturnSlice,
        getPurchaseReturnorder: getOrderReturnSlice,
        getSingleReturn: getSingleOrderReturnSlice,

        getProject: getProjectSlice,
        getStoreEmployee: getStoreEmployeeSlice,

        getAdjustment: getAdjustmentSlice,
        // getItemStock: getItemStockSlice,
        getStoreDashboard: getStoreDashboardSlice,

        getTransactionItem: getTransactionItemSlice,

        getItemSummary: getItemSummarySlice,
        getItemLedger: getItemLedgerSlice,
        getReorderItems: getReorderItemsSlice,

        // Erp =====================================================================
        //Planner ===============================
        getDrawing: getDrawingSlice,
        getUserProfile: getUserProfileSlice,
        getUserIssue: getUserIssueSlice,
        getRequest: getRequestSlice,
        getUserDrawTrasaction: getUserDrawTrasactionSlice,
        getUserAdminDraw: getUserAdminDrawSlice,
        getUserIssueRequest: getUserIssueRequestSlice,
        getUserOffer: getUserOfferSlice,
        getUserIssueAcceptance: getUserIssueAcceptanceSlice,
        getReleseNote: getReleseNoteSlice,

        //packing
        getPacking: getPackSlice,
        // Ececution ==============================================================
        getUserFitup: getUserFitupSlice,
        getUserWeldVisual: getUserWeldVisualSlice,

        getUserJointType: getUserJointTypeSlice,
        getUserNdtMaster: getUserNdtMasterSlice,
        getUserContractor: getUserContractorSlice,
        getUserPaintSystem: getUserPaintSystemSlice,

        getUserWpsMaster: getUserWpsMasterSlice,
        getUserWelderMaster: getUserWelderMasterSlice,
        getUserProcedureMaster: getUserProcedureMasterSlice,
        getUserPaintManufacture: getUserPaintManufactureSlice,

        getStockReport: getStockReportSlice,
        getStockReportList: getStockReportListSlice,

        getUserMainNdtMaster: getUserMainNdtMasterSlice,

        getUserNdtOffer: getUserNdtOfferSlice,

        getUserUtClearance: getUserUtClearanceSlice,
        getUserRtClearance: getUserRtClearanceSlice,

        getUserMptClearance: getUserMptClearanceSlice,
        getUserLptClearance: getUserLptClearanceSlice,

        getUserFinalDimension: getUserFinalDimensionSlice,
        getUserInspectionSummary: getUserInspectionSummarySlice,
        getDispatchNotes: getDispatchNotesSlice,
        getMainStoreStock: getMainStoreStockSlice,

        getUserSurface: getUserSurfaceSlice,
        getMainStock: getMainStockSlice,
        getUserMio: getUserMioSlice,
        getUserFinalCoating: getUserFinalCoatingSlice,

        getInvoice: getInvoiceSlice,
        getDpr: getDprSlice,

        //PMS
        getPmsStock: getPmsStockSlice,

        getUserProjectLocation: getUserProjectLocationSlice,

        // Mutiple =================================================================================================

        getMultipleIssueRequest: getMultipleIssueRequestSlice,
        getMultipleIssueAcc: getMultipleIssueAccSlice,

        getMultipleDrawItems: getMultipleDrawItemsSlice,
        updateMultiGrid: updateMultiGridSlice,

        getMultipleGrid: getMultipleGridSlice,

        updateIssueAccGrid: updateIssueAccGridSlice,
        getMultiFitup: getMultiFitupSlice,
        manageFitupOffTable: manageFitupOffTableSlice,

        getMultiWeldVisual: getMultiWeldVisualSlice,
        updateFitupGrid: updateFitupGridSlice,

        getFitupOfferTable: getFitupOfferTableSlice,
        removeFitupOffTable: removeFitupOffTableSlice,
        updateFitupOffTable: updateFitupOffTableSlice,

        getWeldOfferTable: getWeldOfferTableSlice,
        manageWeldOfferTable: manageWeldOfferTableSlice,
        updateWeldOffTable: updateWeldOffTableSlice,
        removeWeldOffTable: removeWeldOffTableSlice,

        updateNDTGrid: updateNDTGridSlice,
        manageNDTOfferTable: manageNDTOfferTableSlice,
        getNDTOfferTable: getNDTOfferTableSlice,
        removeNDTOffTable: removeNDTOffTableSlice,
        updateNDTOfferTable: updateNDTOffTableSlice,
        getUserMultiNdtMaster: getUserMultiNdtMasterSlice,

        getMultiNdtOffer: getMultiNdtOfferSlice,
    }
})