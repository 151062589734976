import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import Header from '../../../Include/Header';
import Sidebar from '../../../Include/Sidebar';
import PageHeader from '../../Components/Breadcrumbs/PageHeader';
import { getDrawing } from '../../../../../Store/Erp/Planner/Draw/Draw';
import DrawingTable from '../../Components/DrawingTable/DrawingTable';
import SubmitButton from '../../Components/SubmitButton/SubmitButton';
import MultiFdModal from '../../Components/MultiFdModal/MultiFdModal';

const ManageMultiFd = () => {

    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [disable, setDisable] = useState(false);
    const [error, setError] = useState({});
    const [search, setSearch] = useState('');
    const [totalItems, setTotalItems] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [limit, setlimit] = useState(10);
    const [entity, setEntity] = useState([]);

    const [finalArr, setFinalArr] = useState([]);
    const [showItem, setShowItem] = useState(false);
    const data = location.state;

    useEffect(() => {
        dispatch(getDrawing());
    }, []);

    const drawData = useSelector((state) => state?.getDrawing?.user?.data);

    useEffect(() => {
        setEntity(drawData)
    }, [drawData])

    const commentsData = useMemo(() => {
        let computedComments = entity;
        setTotalItems(computedComments?.length);
        return computedComments?.slice(
            (currentPage - 1) * limit,
            (currentPage - 1) * limit + limit
        );
    }, [search, limit, currentPage, entity]);

    const handleAddToArr = () => {

    }

    const handleSubmit = () => {

    }

    const [isSidebarOpen, setIsSidebarOpen] = useState(false);
    const handleOpen = () => {
        setIsSidebarOpen(!isSidebarOpen);
    };

    return (
        <>
            <div className={`main-wrapper ${isSidebarOpen ? "slide-nav" : ""}`}>
                <Header handleOpen={handleOpen} />
                <Sidebar />

                <div className="page-wrapper">
                    <div className="content">
                        <PageHeader breadcrumbs={[
                            { name: "Dashboard", link: "/user/project-store/dashboard", active: false },
                            { name: "Final Dimension Inspection Offer List", link: "/user/project-store/final-dimension-offer-management", active: false },
                            { name: `${data?._id ? 'Edit' : 'Add'} Final Dimension Inspection Offer`, active: true }
                        ]} />

                        <DrawingTable
                            tableTitle={'Drawing List'}
                            commentsData={commentsData}
                            handleAddToIssueArr={handleAddToArr}
                            currentPage={currentPage}
                            limit={limit}
                            setlimit={setlimit}
                            totalItems={totalItems}
                            setCurrentPage={setCurrentPage}
                            setSearch={setSearch}
                            data={data}
                        />

                        <SubmitButton finalReq={data?.items} link='/user/project-store/final-dimension-management'
                            disable={disable} handleSubmit={handleSubmit} buttonName={'Generate Final Dimension Offer'} />
                    </div>
                </div>
            </div>
            <MultiFdModal
                showItem={showItem}
                handleCloseModal={() => setShowItem(false)}
                // drawId={drawId}
                title={'Drawing Grid List'}
            />
        </>
    )
}

export default ManageMultiFd